import Axios from "axios";
import { basicCatch } from "./api-helpers";

const state = {};

export const getters = {};

export const actions = {
  // ROUTES
  // POST
  revokeEbayAccess({ dispatch }) {
    return new Promise((res, rej) => {
      Axios.post("ebay/revoke")
        .then(() => {
          dispatch("getPreferences");
          dispatch("pushNotifications", {
            text: "Successfully revoked RetailRight's access to your eBay account.",
            type: "success",
          });

          res();
        })
        .catch((err) => {
          basicCatch(
            err,
            "Something went wrong while trying to revoke application access to eBay."
          );
          rej();
        });
    });
  },

  createEbayLocation({ dispatch }, storeId) {
    return new Promise((res, rej) => {
      Axios.post("ebay/inventory/location", { store_id: storeId })
        .then(() => {
          dispatch("getPreferences");
          res();
        })
        .catch((err) => {
          basicCatch(
            err,
            "Something went wrong while trying to create a location in eBay."
          );
          rej();
        });
    });
  },

  publishOffers(context, offers) {
    return new Promise((res, rej) => {
      Axios.post("ebay/offers/publish", { offers })
        .then(() => {
          res();
        })
        .catch((err) => {
          basicCatch(
            err,
            "Something went wrong while trying to publish items to eBay."
          );
          rej();
        });
    });
  },

  createEbayListing(context, itemId) {
    return new Promise((res, rej) => {
      Axios.post("ebay/offers/create", { item_id: itemId })
        .then(() => {
          res();
        })
        .catch((err) => {
          basicCatch(
            err,
            "Something went wrong while trying to create a listing on eBay."
          );
          rej();
        });
    });
  },

  withdrawOffer(context, offerId) {
    return new Promise((res, rej) => {
      Axios.post(`ebay/offers/${offerId}/withdraw`)
        .then(() => {
          res();
        })
        .catch((err) => {
          basicCatch(
            err,
            "Something went wrong while trying to un-publish listing from eBay."
          );
          rej();
        });
    });
  },

  deleteOffer(context, offerId) {
    return new Promise((res, rej) => {
      Axios.post(`ebay/offers/${offerId}/delete`)
        .then(() => {
          res();
        })
        .catch((err) => {
          basicCatch(
            err,
            "Something went wrong while trying to delete listing from eBay."
          );
          rej();
        });
    });
  },

  // GET
  authorizeEbay() {
    return new Promise((res, rej) => {
      Axios.get(`ebay/auth-url`)
        .then(({ data }) => {
          if (data.success) {
            res(data.data.auth_url);
          }
        })
        .catch((err) => {
          basicCatch(
            err,
            "Something went wrong while trying to connect to eBay."
          );
          rej();
        });
    });
  },

  getUnpublishedOffers({ dispatch }) {
    return new Promise((res, rej) => {
      Axios.get("ebay/offers/unpublished")
        .then(({ data }) => {
          res(data.data.offers);
          dispatch("setUnpublishedCount", data.data.offers.length);
        })
        .catch((err) => {
          basicCatch(err, "Something went wrong while trying to fetch offers.");
          rej();
        });
    });
  },

  getUnpublishedOffersCount({ dispatch }) {
    return new Promise((res, rej) => {
      Axios.get("ebay/offers/unpublished-count")
        .then(({ data }) => {
          dispatch("setUnpublishedCount", data.data.count);
        })
        .catch((err) => {
          basicCatch(err, "Something went wrong while trying to fetch offers.");
          rej();
        });
    });
  },

  getPublishedOffers(context, lastSeenId) {
    return new Promise((res, rej) => {
      Axios.get("ebay/offers/published", {
        params: { last_seen_id: lastSeenId },
      })
        .then(({ data }) => {
          res(data.data.offers);
        })
        .catch((err) => {
          basicCatch(err, "Something went wrong while trying to fetch offers.");
          rej();
        });
    });
  },

  getDelistedOffers(context, lastSeenId) {
    return new Promise((res, rej) => {
      Axios.get("ebay/offers/delisted", {
        params: { last_seen_id: lastSeenId },
      })
        .then(({ data }) => {
          res(data.data.offers);
        })
        .catch((err) => {
          basicCatch(err, "Something went wrong while trying to fetch offers.");
          rej();
        });
    });
  },

  getCategorySuggestions(context, query) {
    return new Promise((res, rej) => {
      Axios.get(`ebay/category-suggestions?query=${query}`)
        .then(({ data }) => {
          res(data.data);
        })
        .catch((err) => {
          basicCatch(
            err,
            "Something went wrong while trying to get category suggestions."
          );
          rej();
        });
    });
  },

  getEbayBusinessPolicies() {
    return new Promise((res, rej) => {
      Axios.get("ebay/policies")
        .then(({ data }) => {
          res(data.data);
        })
        .catch((err) => {
          basicCatch(
            err,
            "Something went wrong while trying to fetch your eBay business policies"
          );
          rej();
        });
    });
  },
};

export const mutations = {};

export default {
  state,
  getters,
  actions,
  mutations,
};
