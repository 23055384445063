// import * as types from "../mutations";
import Axios from "axios";
import { basicCatch } from "./api-helpers";

const state = {};

export const getters = {};

export const actions = {
  // ROUTES
  // POST
  createBinSales(context, bin) {
    return new Promise((res, rej) => {
      Axios.post("/bin/create-bin-sales", bin)
        .then((response) => {
          const body = response.data;

          if (body.success) {
            res(body);
          }
        })
        .catch((err) => {
          basicCatch(err, "There was an error creating bin sales.");
          rej();
        });
    });
  },

  addToBinSchedule(context, bin) {
    return new Promise((res, rej) => {
      Axios.post("/bin/create/bin-schedule", bin)
        .then((response) => {
          const body = response.data;

          if (body.success) {
            res(body);
          }
        })
        .catch((err) => {
          basicCatch(err, "There was an error adding to bin schedule.");
          rej();
        });
    });
  },

  createItemBinSale(context, bin) {
    return new Promise((res, rej) => {
      Axios.post("/bin/add-items", bin)
        .then((response) => {
          const body = response.data;

          if (body.success) {
            res(body);
          }
        })
        .catch((err) => {
          basicCatch(err, "There was an error creating bin sales.");
          rej();
        });
    });
  },

  restoreItemInBinSale(context, id) {
    return new Promise((res, rej) => {
      Axios.post(`/bin/restore-item/${id}`)
        .then((response) => {
          const body = response.data;

          if (body.success) {
            res(body);
          }
        })
        .catch((err) => {
          basicCatch(err, "Something went wrong restoring the item.");
          rej();
        });
    });
  },

  // GET
  getBinSales() {
    return new Promise((res, rej) => {
      Axios.get(`/bin/bin-sales`)
        .then((response) => {
          const body = response.data;

          if (body.success) {
            res(body);
          }
        })
        .catch((err) => {
          basicCatch(
            err,
            "Something went wrong while trying to fetch the bin sales."
          );
          rej();
        });
    });
  },

  getBins() {
    return new Promise((res, rej) => {
      Axios.get(`/bin`)
        .then(({ data }) => {
          res(data.data);
        })
        .catch((err) => {
          basicCatch(err, "Something went wrong while trying to fetch bins.");
          rej();
        });
    });
  },

  getItemsInBinSales(context, binId) {
    return new Promise((res, rej) => {
      Axios.get(`/bin/items/${binId}`)
        .then((response) => {
          const body = response.data;

          if (body.success) {
            res(body);
          }
        })
        .catch((err) => {
          basicCatch(
            err,
            "Something went wrong while trying to fetch the bin sales."
          );
          rej();
        });
    });
  },

  filterItemsInBinSales(context, data) {
    return new Promise((res, rej) => {
      Axios.post(`/bin/filter-items-in-bin-sales`, data)
        .then((response) => {
          const body = response.data;

          if (body.success) {
            res(body);
          }
        })
        .catch((err) => {
          basicCatch(
            err,
            "Something went wrong while trying to fetch the bin sales."
          );
          rej();
        });
    });
  },

  getDeletedItemsInBinSales(context, id) {
    return new Promise((res, rej) => {
      Axios.get(`/bin/deleted-items/${id}`)
        .then((response) => {
          const body = response.data;

          if (body.success) {
            res(body);
          }
        })
        .catch((err) => {
          basicCatch(
            err,
            "Something went wrong while trying to fetch the items."
          );
          rej();
        });
    });
  },

  getBinSkuItem(context, { sku, store_id }) {
    return new Promise((res, rej) => {
      Axios.get(`/bin/${sku}`, {
        params: {
          store_id,
          day: new Date()
            .toLocaleString("en-us", { weekday: "long" })
            .toLowerCase(),
        },
      })
        .then(({ data }) => {
          res(data.data);
        })
        .catch((err) => {
          basicCatch(err, "Something went wrong while trying to fetch item.");
          rej();
        });
    });
  },

  // DELETE
  deleteItemInBinSale(context, id) {
    return new Promise((res, rej) => {
      Axios.delete(`/bin/item/${id}`)
        .then((response) => {
          const body = response.data;

          if (body.success) {
            res(body);
          }
        })
        .catch((err) => {
          basicCatch(err, "Something went wrong deleting the item.");
          rej();
        });
    });
  },

  deleteBinSale(context, id) {
    return new Promise((res, rej) => {
      Axios.delete(`/bin/${id}`)
        .then(() => {
          res();
        })
        .catch((err) => {
          basicCatch(err, "Something went wrong while trying to delete bin.");
          rej();
        });
    });
  },
};

export const mutations = {};

export default {
  state,
  getters,
  actions,
  mutations,
};
