import * as types from "@/store/mutations";

const state = {
  unpublishedCount: null,
};

export const getters = {
  unpublishedCount: (ebayState) => ebayState.unpublishedCount,
};

export const actions = {
  setUnpublishedCount({ commit }, count) {
    commit(types.SET_EBAY_UNPUBLISHED_COUNT, count);
  },
};

export const mutations = {
  [types.SET_EBAY_UNPUBLISHED_COUNT](ebayState, count) {
    ebayState.unpublishedCount = count;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
