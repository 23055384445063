// Session
export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGOUT_REQUEST = "LOGOUT_REQUEST";
export const AUTHENTICATION_SUCCESS = "AUTHENTICATION_SUCCESS";
export const AUTHENTICATION_FAILURE = "AUTHENTICATION_FAILURE";
export const SET_TOKEN = "SET_TOKEN";
export const SET_QUICKBOOKS_ACCESS_TOKEN = "SET_QUICKBOOKS_ACCESS_TOKEN";
export const CLEAR_USER_STATES = "CLEAR_USER_STATES";
export const UPDATE_PREFERENCES = "UPDATE_PREFERENCES";
export const CLEAR_PREFERENCES = "CLEAR_PREFERENCES";
export const UPDATE_EMPLOYEES = "UPDATE_EMPLOYEES";
export const VERIFY_ACCOUNT = "VERIFY_ACCOUNT";
export const UNVERIFY_ACCOUNT = "UNVERIFY_ACCOUNT";
export const SET_USER = "SET_USER";
export const SET_ORGANIZATION = "SET_ORGANIZATION";
export const SET_ORGANIZATION_ID = "SET_ORGANIZATION_ID";
export const SET_VERSION = "SET_VERSION";
export const SET_NEW_VERSION = "SET_NEW_VERSION";
export const UPDATE_SUBSCRIPTION = "UPDATE_SUBSCRIPTION";
export const SET_SUBSCRIPTION_REQUIRED = "SET_SUBSCRIPTION_REQUIRED";
export const SET_PRINT_ORIGNAL_PRICE = "SET_PRINT_ORIGNAL_PRICE";
// Printing
export const UPDATE_QZ_CONNECTED = "UPDATE_QZ_CONNECTED";
export const UPDATE_QZ_PRINTER = "UPDATE_QZ_PRINTER";
export const UPDATE_QZ_RECEIPT_PRINTER = "UPDATE_QZ_RECEIPT_PRINTER";
export const UPDATE_QZ_PANEL = "UPDATE_QZ_PANEL";
export const SET_WANTS_LABELS = "SET_WANTS_LABELS";
export const SET_WANTS_LABELS_CONFIRMATION = "SET_WANTS_LABELS_CONFIRMATION";

// Pos
export const UPDATE_TERMINAL_SESSION_KEY = "UPDATE_TERMINAL_SESSION_KEY";
export const UPDATE_POS_STORE = "UPDATE_POS_STORE";
export const UPDATE_POS_STATION = "UPDATE_POS_STATION";
export const CLEAR_POS = "CLEAR_POS";

// Notifications
export const UPDATE_NOTIFICATIONS = "UPDATE_NOTIFICATIONS";
export const SET_DB_NOTIFICATIONS = "SET_DB_NOTIFICATIONS";
export const SHIFT_DB_NOTIFICATIONS = "SHIFT_DB_NOTIFICATIONS";
export const CLEAR_NOTIFICATIONS = "CLEAR_NOTIFICATIONS";

// Manifest
export const SET_MANIFEST = "SET_MANIFEST";

// Ebay
export const SET_EBAY_UNPUBLISHED_COUNT = "SET_EBAY_UNPUBLISHED_COUNT";

// API
// Stripe Service
export const SET_SESSION_ID = "SET_SESSION_ID";
export const SET_PAYMENT_METHOD = "SET_PAYMENT_METHOD";

// Return Service
export const SET_CREATED_RETURN = "SET_CREATED_RETURN";

// Reports Service
export const SET_LOADING_REPORT = "SET_LOADING_REPORT";
